// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap";
import '@fortawesome/fontawesome-free/js/all';
import "../stylesheets/application.scss";
import "jquery";
import "../src/reptile"
import "../src/previews"
import "../src/preview"
import "../src/scroll"
import "../src/logs_modal"
import "../src/loading"
import "../src/cocoon"
import moment from 'moment'
window.moment = moment
import Chart from "chart.js/auto";
global.Chart = Chart;

Rails.start()
// Turbolinks.start()
ActiveStorage.start()